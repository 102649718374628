<template>
    <div class="unit-add-edit">
        <!--顶部面包靴-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/baseSetting/unitManagement' }" class="fs20">单位管理</el-breadcrumb-item>
            <el-breadcrumb-item class="fs20">{{ '单位详情' }}</el-breadcrumb-item>
        </el-breadcrumb>
        <!--表单-->
        <div class="mt30 infobox">
            <div class="title">基本信息</div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
                <el-form-item label="单位名称：" prop="name">
                    <span>{{ ruleForm.name || '--' }}</span>
                </el-form-item>
                <el-form-item label="单位全称：" prop="name">
                    <span>{{ ruleForm.fullName || '--' }}</span>
                </el-form-item>
                <el-form-item class="organCode" label="组织机构代码/统一社会信用代码：" prop="name">
                    <span>{{ ruleForm.socialCode || '--' }}</span>
                </el-form-item>
                <el-form-item label="单位类别：" prop="region">
                    <span> {{ getContent(UNITCATEGORY, ruleForm.type, 'label', 'text') || '--' }}</span>
                </el-form-item>
                <el-form-item label="单位性质：" prop="region">
                    <span> {{ getContent(SIGNOPTIONS, ruleForm.characteristic, 'label', 'text') || '--' }}</span>
                </el-form-item>
                <el-form-item label="单位所属系统：" prop="region">
                    <span> {{ getContent(UNITSYSTEM, ruleForm.systemId, 'label', 'text') || '--' }}</span>
                </el-form-item>
                <el-form-item label="单位通信地址：" prop="name">
                    <span>{{ ruleForm.contactAddress || '--' }}</span>
                </el-form-item>
                <el-form-item label="单位联系人：" prop="name">
                    <span>{{ ruleForm.contactPerson || '--' }}</span>
                </el-form-item>
                <el-form-item label="单位联系人手机号：" prop="name">
                    <span>{{ ruleForm.contactPhone || '--' }}</span>
                </el-form-item>
                <el-form-item label="单位联系电话：" prop="name">
                    <span>{{ ruleForm.unitPhone || '--' }}</span>
                </el-form-item>
                <div class="title">政府监管</div>
                <el-form-item label="上级监管单位：" prop="region">
                    <span>{{ ruleForm.supervisedUnitCodeName || '--' }}</span>
                </el-form-item>
                <el-form-item label="单位级别：" prop="region">
                    <span> {{ getContent(UNITLEVEL, ruleForm.unitGrade, 'label', 'text') || '--' }}</span>
                </el-form-item>
                <el-form-item label="行政区划：" class="proviceItem" prop="region">
                    <div class="fl-left">
                        <span>{{ ruleForm.province }}</span>
                        <span>{{ ruleForm.city }}</span>
                        <span>{{ ruleForm.area }}</span>
                        <span>{{ ruleForm.street }}</span>
                        <span>{{ ruleForm.community }}</span>
                    </div>
                    <!--          <div class="fl-left">-->
                    <!--            <div class="provice">所在市：</div>-->
                    <!--            <span>{{ruleForm.city}}</span>-->
                    <!--          </div>-->
                    <!--          <div class="fl-left">-->
                    <!--            <div class="provice">所在区县：</div>-->
                    <!--            <span>{{ruleForm.area}}</span>-->
                    <!--          </div>-->
                    <!--          <div class="fl-left">-->
                    <!--            <div class="provice">所在街道：</div>-->
                    <!--            <span>{{ruleForm.street}}</span>-->
                    <!--          </div>-->
                    <!--          <div class="fl-left">-->
                    <!--            <div class="provice">所在社区：</div>-->
                    <!--            <span>{{ruleForm.community}}</span>-->
                    <!--          </div>-->
                </el-form-item>
                <el-form-item v-if="ruleForm.type === 1" label="上级企业单位：" prop="name">
                    <span>{{ ruleForm.parentEnterpriseName || '--' }}</span>
                </el-form-item>
                <div class="title">账号信息</div>
                <el-form-item label="账号：" prop="name">
                    <span>{{ ruleForm.primaryAccount || '--' }}</span>
                </el-form-item>
                <!--        <el-form-item label="密码："  prop="name">-->
                <!--          <span>密码</span>-->
                <!--        </el-form-item>-->
                <!--        <el-form-item label="确认密码：" prop="name">-->
                <!--          <span>确认密码</span>-->
                <!--        </el-form-item>-->
                <el-row type="flex" justify="center">
                    <el-col :offset="6" :span="10">
                        <el-button @click="$router.go(-1)">返 回</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </div>
</template>

<script>
import { getPensionUnit } from '@/api/unitManage'
import { getContent } from '@/utils/index'
import { UNITCATEGORY, SIGNOPTIONS, UNITSYSTEM, UNITLEVEL } from './dataDictionary'
export default {
    name: 'unitAddAndEdit',
    data() {
        return {
            UNITCATEGORY,
            SIGNOPTIONS,
            UNITSYSTEM,
            UNITLEVEL,
            ruleForm: {},
            rules: {},
            queryParams: {
                id: '',
                type: ''
            }
        }
    },
    mounted() {
        let { id, type, tabs } = this.$route.query
        this.queryParams.id = id
        this.queryParams.type = type
        localStorage.setItem('unitTabs', tabs)
        // console.log(this.$route)
        this.getPensionUnitData()
    },
    methods: {
        getContent,
        async getPensionUnitData() {
            const res = await getPensionUnit(this.queryParams)
            if (res.state === 0) this.ruleForm = res.data
        }
    }
}
</script>

<style lang="scss" scoped>
.unit-add-edit {
    background-color: #ffffff;
    padding: 20px;
    /deep/ .organCode > .el-form-item__label {
        line-height: 20px !important;
    }
    /deep/ .proviceItem {
        margin-bottom: 12px !important;
    }
    .infobox {
        margin-left: 30px;
    }
    .password {
        position: relative;
    }
    .password:after {
        content: '密码可输入6-16位之间的数字或字母（字母区分大小写）,在设置时尽量不要出现连续数字的情况';
        width: 680px;
        position: absolute;
        left: 245px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #666666;
    }
    .fs20 {
        font-size: 14px;
    }
    .mt30 {
        margin-top: 30px;
    }
    .w230 {
        width: 230px;
    }
    .provice {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        padding-right: 20px;
        display: inline-block;
        span {
            display: inline-block;
            padding-right: 20px;
        }
    }
    .fl-left {
        float: left;
        margin-right: 20px;
        margin-bottom: 10px;
        span {
            display: inline-block;
            margin-right: 10px;
        }
    }
    .title {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-bottom: 20px;
    }
}
</style>
